const {fr} = require("date-fns/locale");
module.exports = Object.assign({dateFnsLocale: fr}, {
  "chapo_length_number": 559,
  "date_format": {
    "fr": "d MMMM yyyy"
  },
  "didomi_api_key": "2127e597-3d3f-4aa2-9727-c8126f86298c",
  "notifications_service_endpoint": "https://apim.keolis.com/SIK/000/notification",
  "search_token": "d76d3fdd249e917ef56038b69045af",
  "v3_share_label": {
    "fr": "Je partage"
  },
  "hour_format": {
    "fr": "HH:mm"
  },
  "notifications_service_import_file_endpoint": "https://apim.keolis.com/SIK/000/file/import HTTP/1.1",
  "piano_site_id": "639190",
  "serverless_sendmail_endpoint": "https://apim.keolis.com/dato/send/form/instit/",
  "site_name": "Keolis-lille-metropole.com",
  "v3_social_networks": [
    "TBqBHjjfQ_aYFtqugrqJ-g",
    "M8e0yxi7SCqsSKNNBo26fA",
    "Ynb_ck9HSjWdGu-G6xcGOw",
    "YCL_uaQtRFevDLGy8cIcvQ"
  ],
  "facebook_link": "https://www.facebook.com/sharer/sharer.php?u=",
  "google_tag_manager_id": "GTM-PWPWXKM",
  "meta_description": {
    "fr": "Keolis Lille Métropole est l'exploitant d’ilévia, le réseau de transport de la Métropole Européenne de Lille"
  },
  "notifications_service_token": "2d5392f4a9404b2690515b92a3eadc93",
  "serverless_sendmail_token": "1142321004f3407b98a95856f9820607",
  "google_analytics_key": "",
  "subsidiary_logo": {
    "fr": {
      "path": "/85948/1721650812-logo_keolis_lille_metropole_2c.svg",
      "format": "svg",
      "size": 2131,
      "alt": "Logo de filiale Keolis  Lille Métropole",
      "title": "Keolis Lille Métropole",
      "width": 416,
      "height": 136
    }
  },
  "twitter_link": "https://twitter.com/intent/tweet?url=",
  "linkedin_link": "https://www.linkedin.com/sharing/share-offsite/?url=",
  "subsidiary_reduced_logo": {
    "fr": {
      "path": "/85948/1724158599-logo_k_bleu-size5.svg",
      "format": "svg",
      "size": 754,
      "alt": "Logo Keolis",
      "title": "Keolis",
      "width": 256,
      "height": 256
    }
  },
  "home_page_title": {
    "fr": "Accueil"
  },
  "mailto_addresse": "mailto:someone@example.com",
  "share_buttons_title": {
    "fr": "Partager"
  },
  "transport_mode_title": {
    "fr": "Modes de transport"
  },
  "facebook_label": {
    "fr": "Facebook"
  },
  "token_google_site_verification": "Keolis Lille Métropole",
  "twitter_label": {
    "fr": "Twitter"
  },
  "linkedin_label": {
    "fr": "LinkedIn"
  },
  "mailto_label": {
    "fr": "Partager par mail"
  },
  "download_label": {
    "fr": "Télécharger"
  },
  "updated_at": "2024-12-04T18:17:37.435+01:00",
  "created_at": "2021-04-20T17:40:04.593+02:00",
  "locale": "fr",
  "locales": [
    "fr"
  ],
  "siteUrl": "https://www.keolis-lille-metropole.com/"
})